import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css'; // Ensure that this file exists for styling

const Chat = () => {
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatWindowRef = useRef(null);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [responses, isLoading]);

    const sendMessage = async () => {
        const trimmedMessage = message.trim();
        if (!trimmedMessage) return;

        setResponses(prevResponses => [...prevResponses, { sender: 'Jeg', text: trimmedMessage }]);
        setMessage('');
        setIsLoading(true);

        try {
            const response = await axios.post('https://backend-dot-hai1-437508.ew.r.appspot.com/api/chat', { message: trimmedMessage });
            console.log(response.data);

            setResponses(prevResponses => [...prevResponses, { sender: 'HAI', text: response.data.response }]);
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMsg = error.response && error.response.data && error.response.data.response
                ? error.response.data.response
                : 'Beklager, noe gikk galt.';

            setResponses(prevResponses => [...prevResponses, { sender: 'HAI', text: errorMsg }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <div className="chat-container">
            <div className="icon"></div> {/* Add the icon here */}
            <h1>HAI - Heste AI</h1>
            <div className="chat-window" ref={chatWindowRef}>
                {responses.map((resp, index) => (
                    <div key={index} className={`message ${resp.sender === 'HAI' ? 'HAI' : 'Jeg'}`}>
                        <strong>{resp.sender}:</strong> {resp.sender === 'HAI' ? renderTextWithLineBreaks(resp.text) : resp.text}
                    </div>
                ))}
                {isLoading && <div className="message HAI"><strong>HAI:</strong> Skriver...</div>}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Spør meg om heste tips..."
                    className="message-input"
                />
                <button onClick={sendMessage} className="send-button">Send</button>
            </div>
        </div>
    );
};

export default Chat;


