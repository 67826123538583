// src/App.js

import React from 'react';
import Chat from './Chat'; // Import the Chat component
import './Main.css'; // Import the nye Main.css

function App() {
    return (
        <div className="main-container">
            <Chat /> {/* Render Chat-komponenten */}
        </div>
    );
}

export default App;

